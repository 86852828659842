var FlashMessage = {
  template: `<div class="user-msg user-msg--<type>">
                    <div class="js-close-user-msg user-msg__close">
                      <svg class="icon icon--xs svg--close"><use xlink:href="#svg--close"></use></svg>
                    </div>
                    <p class="user-msg__title"><typeCapitalized></p>
                    <p class="user-msg__text"><textMessage></p>
                </div>`,
  shortSuccessMessageTemplate: `<div class="message">
        <div class="callout callout--success user-msg">
            <div class="callout__status callout__status--success">
                <svg class="icon icon-svg--suggested--check-circle">
                    <use xlink:href="#svg--suggested--check-circle"></use>
                </svg>
            </div>
            <p class="montserratRegular fs-14 sp-gray-8">
                <textMessage>
            </p>
        </div>
    </div>`,
  shortWarningMessageTemplate: `<div class="message">
        <div class="callout callout--warning user-msg">
            <div class="callout__status callout__status--warning">
                <svg class="icon icon-svg--suggested--warning-circle">
                    <use xlink:href="#svg--suggested--warning-circle"></use>
                </svg>
            </div>
            <p class="montserratRegular fs-14 sp-gray-8">
                <textMessage>
            </p>
        </div>
    </div>`,
  shortInfoMessageTemplate: `<div class="message">
        <div class="callout user-msg">
            <div class="callout__status callout__status--info">
                <svg class="icon icon-svg--suggested--info-circle">
                    <use xlink:href="#svg--suggested--info-circle"></use>
                </svg>
            </div>
            <p class="montserratRegular fs-14 sp-gray-8">
                <textMessage>
            </p>
        </div>
    </div>`,
  shortErrorMessageTemplate: `<div class="message">
        <div class="callout callout--error user-msg" data-closable>
            <div class="callout__status callout__status--error">
                <svg class="icon icon-svg--suggested--close-circle">
                    <use xlink:href="#svg--suggested--close-circle"></use>
                </svg>
            </div>
            <p class="montserratRegular fs-14 sp-gray-8"><textMessage></p>
            <button class="close-button" aria-label="Dismiss alert" type="button" data-close="">
                <span aria-hidden="true">×</span>\n
            </button>
        </div>
    </div>`,
  // Timer
  // Set exparation timer to user messages
  timer: 8500,
  showFlashMessagesCallback: function (whatIsClicked, response){
    const _this = this;
    const divFlash = $('#js-flash-message');

    if (typeof whatIsClicked === 'undefined' || whatIsClicked == 'save' || whatIsClicked == 'delete'){
      const url = divFlash.attr('data-url');
      $.ajax({
        method: 'GET',
        url: url,
        success: function (data){
          divFlash.html(data.responseView);
          setTimeout(function (){
            $('.user-msg').addClass('user-msg--close');
          }, _this.timer);
        }
      });
    }
  },
  displayFlashMessages: function (flashMessageObject){
    const _this = this;
    const divFlash = $('#js-flash-message');
    let userMsg = '';
    const messageType = flashMessageObject.messageType;
    let messageTitle = flashMessageObject.messageTitle;
    const messageContent = flashMessageObject.message;
    if (!messageTitle){
      messageTitle = messageType;
    }
    userMsg = FlashMessage.getMsg(messageContent, messageType, messageTitle);
    divFlash.html(userMsg);
    setTimeout(function (){
      $('.user-msg').addClass('user-msg--close');
    }, _this.timer);
  },
  showShortMessage: function (flashMessageObject){
    const _this = this;
    const message = flashMessageObject.content;
    const type = flashMessageObject.type;
    const divFlash = $('#js-flash-message');
    let template = _this.shortSuccessMessageTemplate;
    switch (type){
      case 'success': template = _this.shortSuccessMessageTemplate; break;
      case 'error': template = _this.shortErrorMessageTemplate; break;
      case 'warning': template = _this.shortWarningMessageTemplate; break;
      case 'info': template = _this.shortInfoMessageTemplate; break;
    }

    const messageHtml = template.replace('<textMessage>', message);
    divFlash.html(messageHtml);
    setTimeout(function (){
      $('.user-msg').addClass('user-msg--close');
    }, _this.timer);
  },
  showErrorMessage: function (messageText){
    const _this = this;
    const message = messageText;
    const divFlash = $('#js-flash-message');
    const messageHtml = _this.shortErrorMessageTemplate.replace('<textMessage>', message);
    divFlash.html(messageHtml);
  },
  /*
     * whatIsClicked - FlashMessage is used in AjaxForm, and in ajaxform click could be on submit, edit or cancel
     * message - String, if not passed ajax is triggered to get flashes from Session
     */
  showFlashMessages: function (message, messageType = 'success', messageTitle = false){
    const _this = this;
    const divFlash = $('#js-flash-message');
    let userMsg = '';

    messageTitle = messageTitle || messageType;
    userMsg = FlashMessage.getMsg(message, messageType, messageTitle);
    divFlash.html(userMsg);
    setTimeout(function (){
      $('.user-msg').addClass('user-msg--close');
    }, timer);
  },
  getMsg: function (message, messageType, messageTitle){
    const _this = this;
    var messageTitle = messageTitle.charAt(0).toUpperCase() + messageTitle.slice(1);
    return _this.template.replace('<type>', messageType)
      .replace('<typeCapitalized>', messageTitle)
      .replace('<textMessage>', message);
  },
  getDangerMsg: function (message){
    const _this = this;

    return _this.template.replace('<type>', 'danger')
      .replace('<typeCapitalized>', 'Error')
      .replace('<textMessage>', message);
  },
  getSuccessMsg: function (message){
    const _this = this;

    return _this.template.replace('<type>', 'success')
      .replace('<typeCapitalized>', 'Success')
      .replace('<textMessage>', message);
  },
  getInfoMsg: function (message){
    const _this = this;

    return _this.template.replace('<type>', 'info')
      .replace('<typeCapitalized>', 'Info')
      .replace('<textMessage>', message);
  },
  getWarningMsg: function (message){
    const _this = this;

    return _this.template.replace('<type>', 'warning')
      .replace('<typeCapitalized>', 'Warning')
      .replace('<textMessage>', message);
  }
};
